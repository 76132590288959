.Start--container {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.Start--logo {
    margin: 0 auto;
    margin-top: 100px;
    width: 250px;
    height: 180px;
}

.Start--description {
    margin: 20px 0 80px 0;
    font: 16px 'Roboto-Thin';
}

.Start--top-shape {
    width: 300px;
    height: 200px;
    background-color: var(--navy);
    position: absolute;
    right: -160px;
    top: -80px;
    transform: rotate(50deg);
}

.Start--bottom-shape {
    width: 400px;
    height: 300px;
    background-color: var(--green);
    position: absolute;
    left: -160px;
    bottom: -180px;
    transform: rotate(-30deg);
}

.Start--footer {
    margin: 50px 0 0 0;
    padding: 0 0 20px 0;
}

.Start--footer-attribution {
    font: 13px 'Roboto-Regular';
    margin: 5px;
}

.Start--spotify-text {
    color: var(--green);
}

.Start--developer-text {
    color: var(--black);
}

.Start--github-link,
.Start--github-link:visited {
    text-decoration: underline;
    color: var(--black);
}

.Start--api-status {
    color: var(--black);
    text-decoration: none;
    font: 13px 'Roboto-Regular';
}

@media only screen and (max-width: 800px) {
    .Start--bottom-shape {
        width: 400px;
        height: 300px;
        background-color: var(--green);
        position: absolute;
        left: -200px;
        bottom: -250px;
        transform: rotate(-30deg);
    }
}

@media only screen and (max-width: 400px) {
    .Start--bottom-shape {
        width: 400px;
        height: 300px;
        background-color: var(--green);
        position: absolute;
        left: -240px;
        bottom: -300px;
        transform: rotate(-30deg);
    }

    @media only screen and (max-height: 600px) {
        .Start--bottom-shape {
            display: none;
        }
    }
}

.Result--container {
    background: linear-gradient(180deg, var(--white) 0%, var(--green) 100%);
    flex: 1;
    padding: 0 0 20px 0;
}

.Result--container.correct {
    background: linear-gradient(180deg, var(--white) 0%, var(--green) 100%);
}

.Result--container.incorrect {
    background: linear-gradient(180deg, var(--white) 0%, var(--pink) 100%);
}

.Result--album-image {
    margin: 80px 0 0 0;
    width: 200px;
    height: 200px;
    background-color: var(--dark-gray);
}

.Result--result {
    font: 36px 'Roboto-Bold';
    color: var(--white);
    padding: 10px;
    margin: 10px;
}

.Result--payload {
    font: 24px 'Roboto-Regular';
    color: var(--white);
    margin: 10px 10px 40px 10px;
}

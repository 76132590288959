.Artist--container {
    background-color: var(--white);
    color: var(--black);
    cursor: pointer;
    height: 70px;
    width: 330px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
    border: none;
    font: 24px 'Roboto-Thin';
    padding: 7px;
}

.Artist--container:hover {
    background-color: var(--light-navy);
    color: var(--white);
}

.Artist--container span {
    font: 22px 'Roboto-Regular';
}

.Artist--image {
    width: 50px;
    max-height: 50px;
    margin-right: 20px;
    background-color: var(--dark-gray);
}

.Artist--name {
    margin-right: 20px;
    text-align: left;
}

.Artist--number {
    margin: 0 10px;
    width: 20px;
}

.FinalResult--container {
    padding: 20px 0;
}

.FinalResult--albums {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.FinalResult--single-album {
    width: 150px;
    height: 150px;
    background-color: var(--dark-gray);
    margin: 5px;
}

.FinalResult--single-album.incorrect {
    opacity: 0.2;
}

.FinalResult--user-text {
    font: 30px 'Roboto-Regular';
    color: var(--black);
}

.FinalResult--score-container {
    margin-top: 20px;
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
}

.FinalResult--score-background {
    grid-column-start: 1;
    grid-row-start: 1;
}

.FinalResult--social-container {
    margin-top: 20px;
}

.FinalResult--social-container > button {
    margin: 0 20px;
}

.FinalResult--score-text {
    color: var(--black);
    grid-column-start: 1;
    grid-row-start: 1;
    font: 40px 'Roboto-Regular';
}

.FinalResult--score-text-span {
    color: var(--black);
    font: 18px 'Roboto-Thin';
}

@media only screen and (max-width: 800px) {
    .FinalResult--single-album {
        width: 100px;
        height: 100px;
    }

    .FinalResult--score-text {
        font: 35px 'Roboto-Regular';
    }
}

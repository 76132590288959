.Option--button {
    border-radius: 50%;
    width: 5em;
    height: 5em;
    color: var(--white);
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: var(--white);
    border: none;
    font: 24px 'Roboto-Thin';
    margin: 0 auto;
    padding: 7px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Option--button:hover {
    transition: transform 200ms;
    transform: scale(0.95);
}

.Option--button:active {
    transition: transform 1s;
    transform: scale(1.1);
}

.Option--button p {
    align-self: center;
}

.navy {
    background-color: var(--navy);
}

.yellow {
    background-color: var(--yellow);
}

.green {
    background-color: var(--green);
}

.pink {
    background-color: var(--pink);
}

.Button--button {
    background-color: var(--white);
    border: none;
    border-radius: 8px;
    color: var(--black);
    font: 24px 'Roboto-Thin';
    cursor: pointer;
    min-height: 45px;
    width: 130px;
    margin: 0 auto;
    padding: 7px;
}

.Round--container {
    padding: 20px 0;
}
.Round--album-image {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    background-color: var(--dark-gray);
}

.Round--artists-container {
    display: grid;
    justify-content: center;
}

/* Common variables */

:root {
    /* Colors */
    --white: #fff;
    --yellow: #ffd166;
    --green: #06d6a0;
    --navy: #26547c;
    --pink: #ef476f;
    --black: #000;
    --gray: #f4f5f6;
    --light-navy: #26547c7b;
    --dark-gray: #616c76;
}

/* Fonts */
@font-face {
    font-family: 'Roboto-Thin';
    src: url('./assets/fonts/Roboto/Roboto-Thin.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Thin-Italic';
    src: url('./assets/fonts/Roboto/Roboto-ThinItalic.ttf');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
}

html,
body {
    margin: 0;
    min-height: 100%;
    display: flex;
    flex: 1;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

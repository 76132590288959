.RoundSelection--container {
    padding-top: 80px;
}

.RoundSelection--options-container {
    margin: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
}

.RoundSelection--question {
    font: 26px 'Roboto-Thin';
}
